<template>
  <div class="bg">
    <!-- <Header :currentNav="0"></Header> -->

    <div class="fullImg">
      <div>
        <img src="../assets/images/header/bg.jpg" alt="Navigate DeFi with
World-Class Banking" />
      </div>
    </div>

    <div class="news">
      <div class="newstitle">
        <div class="l">What’s New</div>
        <div class="r" @click="$router.push('/news')">View More</div>
      </div>
      <div class="cards flexBox">
        <div
          class="card"
          v-for="item in list"
          :key="item.guid"
          style="cursor: pointer"
          @click="
            $router.push({
              path: `/newsDetail/${item.guid}`,
            })
          "
        >
          <el-card>
            <div>
              <img class="card-img" :src="item.article_image" alt="" />
              <div class="card-body">
                <div class="card-title">
                  {{ item.article_title }}
                </div>
                <div class="card-time">
                  Release date：{{
                    $dayjs(item.article_publish_time * 1000).format('ll')
                  }}
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div class="fullImg">
      <div class="seobody">
        <h3 class="seott">
          RWA Off Exchange(ROE）<span>Solution Highlight</span>
        </h3>
        <div class="flexSolu">
          <div>
            Isolate Counterparty Risk
          </div>
          <div>
            Integrate with any <br/>
            Exchange Custody Solutions
          </div>
          <div>
            Connect Trad-Fi <br/>
            Liquidity to Web3
          </div>
        </div>
        <img src="../assets/images/index/homebar1.jpg" alt="RWA Off Exchange(ROE）" />
      
      </div>
    </div>
    <div class="fullImg">
      <div class="dmzconBody">
        <div class="l">
          <h5 class="contt">
            DMZ Concept
          </h5>
          <div class="ro2">
            DMZ.finance is a financial tehnology company headquartered in Singapore.
          </div>
          <div class="ro3">
            The concept of DMZ comes from Demilitarized Zone, meaning a risk-isolating middle zone between two interconnected camps, TradFi and Crypto World.
          </div>
        </div>
        <img class="r" src="../assets/images/index/dmzcon.jpg" alt="RWA Off Exchange(ROE）" />
      </div>
    </div>
    <div class="fullImg">
      <div class="tesseo">
        <div class="testt">
        Testimonials
      </div>
      <div class="tesleft1">
        One day we are going to look back and say what a significant step DMZ has taken to bridge the gap between RWA and virtual assets.
      </div>
      <div class="tesleft2">
        — Lee Chiow Wei， CFA <br>
Head of Asset Management， QNB Singapore
      </div>
      <div class="tesright1">
        This is so exciting, we have been looking for relevant solutions, and this is the most suitable solution for our traditional financial investors to enter the market.

      </div>
      <div class="tesright2">
        — Ruiqi Tian  <br>
Head of Quantitative Team, Derivatives China 
      </div>
      <div><img src="../assets/images/index/tes.png" /></div>
      </div>
    </div>
   
    <div class="qabox">
      <div class="safeW">
        <div
          class="faq"
          @click="
            $router.push({
              name: 'knowledge',
            })
          "
        >
          FAQ
        </div>
        <div class="qacontainer" style="flex: 1">
          <div class="qaUnit" v-for="(item, index) in qList" :key="index">
            <div class="q" @click="qslide(index)">
              <div class="arrowdown">
                <i class="el-icon-minus" v-if="qStateList[index] == true"></i>
                <i class="el-icon-plus" v-else></i>
              </div>
              {{ item.article_title }}
            </div>
            <div class="a" v-if="qStateList[index] == true">
              <div v-html="item.article_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Bottom from '@/components/Bottom'
import { smsCode, loginMobile, getPost, getGet } from '../api/apiList'
import { getAboutList } from '@/api/about'

import qs from 'qs'
export default {
  components: { Header, Bottom },
  data() {
    return {
      qStateList: [],
      qList: [],
      indexSwipe: {},
      list: [],
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    // var that = this
    // this.indexSwipe = new Swiper('.swiper-container', {
    //   autoplay: false,
    //   loop: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    //   on: {
    //     tap: function (swiper, event) {
    //       that.$router.push({
    //         path: '/reg',
    //       })
    //     },
    //     resize:function(){
    //       setTimeout(()=>{
    //         that.indexSwipe.update();
    //       },100)
    //     }
    //   },
    // })

    setTimeout(() => {
      this.initSwipe()
    }, 1000)

    //this.qStateList = [];

    this.getIndexQa()
  },
  methods: {
    async getList() {
      const res = await getAboutList('news', 1, 3)
      console.log(res)
      this.list = res.data.articles
      this.total = Number(res.data.total_count)
    },
    goAprtp() {
      window.open(' https://aprtp.com')
    },
    initSwipe() {
      var that = this
      this.indexSwipe = new Swiper('.swiper-container', {
        autoplay: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          tap: function (swiper, event) {
            that.$router.push({
              path: '/reg',
            })
          },
          resize: function () {
            setTimeout(() => {
              that.indexSwipe.update()
            }, 100)
          },
        },
      })
    },

    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },

    goinvestList() {
      this.$router.push({
        path: '/investList',
      })
    },

    getIndexQa() {
      let url1 = 'stox_home/index_qa'
      let data1 = {}

      getPost(url1, data1).then((res) => {
        if (res.code == 1) {
          this.qList = res.data

          for (var i = 0; i < res.data.length; i++) {
            this.qStateList.push(false)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    qslide(i) {
      // console.log('e',e);
      for (var j = 0; j < this.qList.length; j++) {
        if (j == i) {
        } else {
          this.qStateList[j] = false
        }
      }
      this.qStateList[i] = !this.qStateList[i]
      this.$forceUpdate()
      console.log('qStateList', this.qStateList)
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  background: #000;
}
.dmzconBody{
  box-sizing: border-box;
  color: #fff;
  display: flex;
  padding: 100px 350px;
  .l{
    flex: 1;
    margin-right: 30px;
    .contt{
      width: 300px;
      font-size: 40px;
      margin-bottom: 40px;
    }
    .ro2{
      width: 450px;
      color: #e0e0e0;
      font-size: 22px;
      margin-bottom: 20px;
      line-height: 40px;
    }
    .ro3{
      width: 600px;
      color: #e0e0e0;
      font-size: 22px;
      line-height: 40px;
    }
  }
  .r{
      width: 60%;
    // width: 300px !important;
    
  }
}
.tesseo{
  color: #fff;
  .testt{
    width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left:50%;
    transform: translateX(-50%);
    font-size: 50px;
  }
}
.tesleft1{
  width: 532px;
  position: absolute;
  left: 400px;
  top: 180px;
  font-size: 24px;
  line-height: 40px;
}
.tesleft2{
  text-align: right;
  width: 532px;
  position: absolute;
  left: 400px;
  top: 450px;
  font-size: 24px;
}

.tesright1{
  width: 532px;
  position: absolute;
  left: 1050px;
  top: 180px;
  font-size: 24px;
  line-height: 40px;

}
.tesright2{
  text-align: right;
  width: 532px;
  position: absolute;
  left: 1050px;
  top: 450px;
  font-size: 24px;
}

.seobody{
  position: relative;
  overflow: hidden;
  .seott{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 200px;
    color: #fff;
    text-align: center;
    font-size: 50px;
    span{
      color: #19DD19;
    }
  }
  .flexSolu{
    color: #fff;
    position: absolute;
    justify-content:space-evenly;
    display: flex;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    div{
      font-size: 32px;
      text-align: center;
      flex:1;
      width: 80%;
    }
  }
 

  
}
.fullImg {
  position: relative;
  min-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
 
  .logoTitle {
    left: 0 !important;
    width: 1200px;
    margin: 50px auto;
    line-height: 50px;
    font-size: 30px;
    color: #fff;
  }
  > div {
    position: relative;
    width: 1920px;
    left: 50%;
    margin-left: -960px;
    img {
      width: 1920px;
      vertical-align: bottom;
    }
    .viewmore {
      font-size: 18px;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container > .swiper-wrapper > .swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative;

  width: 100%;
  height: 725px;
}
::v-deep .swiper-container {
  // --swiper-theme-color: #ff6600;
  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: blue;
  }
}

.qabox {
  background: url('../assets/images/index/faqbg.jpeg') no-repeat;
}

.safeW {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 70px 0;
  display: flex;
  .faq {
    cursor: pointer;
    margin-top: 30px;
    margin-right: 100px;
    font-size: 50px;
    color: #fff;
  }
}
.qatitle {
  color: #2f2f2f;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 94px;
}

.qaUnit {
  border-bottom: 1px solid #acacac;
  margin-bottom: 20px;
  > .q {
    font-size: 20px;
    color: #fff;
    padding: 20px;
    padding-left: 40px;
    padding-right: 60px;
    position: relative;
    cursor: pointer;
    > i {
      position: absolute;
      left: 20px;
      top: 23px;
      width: 4px;
      height: 22px;
      background: #da3c3f;
    }
    > .arrowdown {
      position: absolute;
      right: 20px;
      top: 23px;
      i {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  > .a {
    border-top: 1px solid #dcddde;
    padding: 20px;
    font-size: 16px;
    word-break: break-word;
    color: #d7d3d3;
    > .more {
      text-align: right;
      text-decoration: underline;
      color: #6b89e7;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.logoBox {
  background: #fff;
  margin: 0 auto !important;
  left: 0 !important;
  overflow: hidden;
  border-radius: 40px;
  width: 1200px !important;
  .bn {
    cursor: pointer;
    width: 100% !important;
  }
  .logoList {
    margin: 70px auto;
    display: flex;
    .r {
      flex: 1;
    }
  }
}

.row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .l {
    text-align: right;
    width: 151px;
    margin-right: 10px;
  }
  img {
    width: auto !important;
    margin-right: 5px;
  }
}

.row11 {
  height: 18px;
}
.row12 {
  height: 17px;
}
.row13 {
  height: 24px;
}
.row21 {
  height: 42px;
}
.row22 {
  height: 60px;
}

.row31 {
  height: 25px;
}
.row32 {
  height: 44px;
}
.row33 {
  height: 40px;
}
.row41 {
  height: 40px;
}
.row42 {
  height: 42px;
}
.row43 {
  height: 43px;
}
.row44 {
  height: 23px;
}
.row51 {
  height: 25px;
}
.row61 {
  height: 32px;
}
.row62 {
  height: 30px;
}
.row63 {
  height: 25px;
}
.row71 {
  height: 40px;
}
.row72 {
  height: 40px;
}
.row73 {
  height: 27px;
}
.row81 {
  height: 40px;
}
.row82 {
  height: 40px;
}
.row83 {
  height: 40px;
}
.row91 {
  height: 20px;
}
.row92 {
  height: 27px;
}
.row93 {
  height: 40px;
}
.row101 {
  height: 0px;
}

.row23 {
  height: 30px;
}

.row74 {
  height: 26px;
}
.bgtt {
  color: #fff;
  position: absolute;
  top: 200px;
  left: 355px;
  .na {
    font-family: MiSans, MiSans;
    font-weight: bold;
    font-size: 56px;
  }
  .our {
    font-size: 26px;
  }
}
.news {
  width: 1200px;
  margin: 0 auto;
}
.newstitle {
  margin-bottom: 62px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .l {
    font-size: 50px;
    font-weight: bold;
  }
  .r {
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }
}
.cards {
  margin-top: 50px;
  justify-content: space-between;
}
.card {
  margin-bottom: 42px;
  width: 355px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.16);
  .card-img {
    width: 100%;
    height: 200px;
  }
  .card-body {
    padding: 23px;
    .card-title {
      height: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      line-height: 30px;
      margin-bottom: 18px;
    }
    .card-time {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    .card-knowmore {
      font-size: 15px;
      font-weight: 400;
      color: #4cd343;
      line-height: 24px;
      text-decoration: underline;
    }
  }
}
.cardTitle {
  text-align: center;
  margin: 0 auto;
  width: 1000px;
  line-height: 200px;
  font-size: 62px;
  border-bottom: 1px solid #707070;
}
.el-card {
  border: 0px;
  background: linear-gradient(145deg, #2c2b2b 0%, #2c2b2b 100%);
  color: #707070;
}
::v-deep {
  .card .card-body .card-time {
    font-size: 18px !important;
  }
}
</style>

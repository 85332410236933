<template>
  <div id="app">
    <Header v-if="$route.name !== 'next'"></Header>
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header'
export default {
  components: { Header },
  mounted() {
    var ua = navigator.userAgent
    var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/),
      isMobile = isIphone || isAndroid
    if (isMobile) {
      const host =
        location.origin.indexOf('192.168') > 0 ? '/api' : location.origin
    } else {
      var w = $(window).width()
      console.log('screenW', w)

      if (w > 1300) {
        //this.autoAdjust()
      } else {
        // var t = window.devicePixelRatio;
        // document.body.style.zoom =1/ t;
        // var zoomRatio = 1/t;
        // $('body').css('zoom',zoomRatio)
      }
    }
  },
  methods: {
    autoAdjust(targetWidth = 1920) {
      const isFox = navigator.userAgent.indexOf('Firefox') > -1 ? true : false
      let adjustWindow = () => {
        const ratio = document.documentElement.clientWidth / targetWidth
        const htmlHeight =
          (document.documentElement.clientHeight * targetWidth) /
            document.documentElement.clientWidth +
          'px'

        document.documentElement.style.height = htmlHeight
        if (isFox) {
          console.log('isfox')
          // document.documentElement.style.transform = `scale(${ratio})`
          // $('html').css('transform-origin','left top')
          // $('html').css('width','1899px')
          // $('html').css('overflow-x','hidden')
          // console.log('firefoxratio',ratio);

          $(function () {
            // $('#app').css('transform',`inherit`)
            // $('#app').css('display',`inline-table`)
            // $('#app').css('transform',`scale(${ratio})`)
            // $('#app').css('transform-origin','left top')
            // $('#app').css('width','1900px')
            // $('#app').css('height', $('#app').height()*ratio);
            // $('html').css('overflow-x','hidden')
          })

          // var appscale =

          // setTimeout(()=>{

          //   console.log('appH', $('#app').height());
          //   console.log('appdivH',$('#app>div').height());

          //   setTimeout(()=>{
          //     console.log('appH', $('#app').height());
          //     console.log('appdivH',$('#app>div').get(0).clientHeight);
          //   },1000)

          //  },200)

          //document.getElementById('app').style.transform = `scale(${ratio})`
          // document.body.style.zoom = ratio;
          // $('body').css('zoom',ratio);
        } else {
          console.log('isother')
          document.documentElement.style.zoom = ratio
        }
        document.documentElement.setAttribute('data-ratio', ratio)
        let allTag = document.getElementsByTagName('*')
        for (let i = 0; i < allTag.length; i++) {
          const currentMarginTop = window.getComputedStyle(allTag[i]).marginTop
          if (currentMarginTop !== '0px' || currentMarginTop !== 'auto') {
            allTag[i].style.marginTop =
              Number(currentMarginTop.slice(0, -2)) *
                (document.documentElement.clientHeight / 1080 / ratio) +
              'px'
          }
          const paddingTop = window.getComputedStyle(allTag[i]).paddingTop
          if (paddingTop !== '0px' || paddingTop !== 'auto') {
            allTag[i].style.paddingTop =
              Number(paddingTop.slice(0, -2)) *
                (document.documentElement.clientHeight / 1080 / ratio) +
              'px'
          }
        }
      }
      adjustWindow()
      // window.addEventListener('resize', adjustWindow);
      // // 使鼠标坐标一致
      // let x_get = Object.getOwnPropertyDescriptor(MouseEvent.prototype, 'x')
      //     .get;
      // let y_get = Object.getOwnPropertyDescriptor(MouseEvent.prototype, 'y')
      //     .get;
      // Object.defineProperties(MouseEvent.prototype, {
      //     R: {
      //         get: function () {
      //             return parseFloat(
      //                 document.documentElement.getAttribute('data-ratio')
      //             );
      //         }
      //     },
      //     x: {
      //         get: function () {
      //             return x_get.call(this) / this.R;
      //         }
      //     },
      //     y: {
      //         get: function () {
      //             return y_get.call(this) / this.R;
      //         }
      //     }
      // });
      if (isFox) {
        let getBoundingClientRect = Element.prototype.getBoundingClientRect
        Element.prototype.getBoundingClientRect = function () {
          let value = getBoundingClientRect.call(this)
          let ratio = parseFloat(
            document.documentElement.getAttribute('data-ratio')
          )
          value = new Proxy(value, {
            get: function (target, proper) {
              return Reflect.get(target, proper) / ratio
            },
          })
          return value
        }
      }
    },
  },

  // created() {
  //   let userInfo = this.$store.state.userInfo
  //   console.log(this.$store.state.userInfo)
  //   if (userInfo === '' || userInfo === null || userInfo === undefined) {
  //     this.$router.push('login')
  //   }
  // },
}
</script>

<style lang="less" scoped>
#app {
  min-height: 100vh;
  background: #000;
  // font-family: Reguluar;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'myFont';
}
::v-deep .el-card__body {
  padding: 0;
  height: 100%;
}
::v-deep {
  .el-card,
  .el-message {
    border-radius: 10px;
  }
  .el-card {
    height: 100%;
  }
  .el-select-dropdown__item.selected {
    color: #da3c3f;
  }
}

@font-face {
  font-family: 'myFont';
  src: url('../src/assets/font/MiSans-Normal.ttf');
}
</style>

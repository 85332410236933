import server from '../api/server'

const proxy_tdd =
  location.origin.indexOf('qy-uat.tandidi.com') >= 0
    ? '/stores/tdd/'
    : '/stores/tdd/'

// if (process.env.NODE_ENV == 'production') {
//   proxy_tdd = '/chain/tddchain/'
// } else {
//   proxy_tdd = '/stores/tddchain/'
// }
// data2 = qs.stringify(data)
//发送手机验证码
export const smsCode = (url, data) => {
  return server.requestPost(proxy_tdd + url, data)
}
//手机验证码登录
export const loginMobile = (url, data) => {
  return server.requestPost(proxy_tdd + url, data)
}
//新闻Banner
export const newsBanner = (url, data) => {
  return server.requestPost(proxy_tdd + url, data)
}
export const getPost = (url, data) => {
  return server.requestPost(proxy_tdd + url, data)
}

export const getGet = (url, data) => {
  return server.requestGet(proxy_tdd + url, data)
}

export const getJsonPost = (url, data) => {
  return server.requestPostJson(proxy_tdd + url, data)
}

export const getJsonMultpart = (url, data) => {
  return server.requestMuti(proxy_tdd + url, data)
}

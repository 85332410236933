import Message from './Message.vue'
let MessageInstanse = null
const MessagePlugin = {
  install(Vue) {
    if (!MessageInstanse) {
      // Vue.extend() 方法创建了一个名为 Message 的组件构造器。
      const MessageComponent = Vue.extend(Message)
      MessageInstanse = new MessageComponent()
      // 将通知组件挂载到DOM中
      const container = document.createElement('div')
      document.body.appendChild(container)
      MessageInstanse.$mount(container)
    }
    Vue.prototype.$Message = {
      // 加载
      loading: (message, duration = 1000 * 60 * 60 * 24) => {
        MessageInstanse.showMessage(message, 'loading', duration)
      },
      // 成功
      success: (message, duration) => {
        MessageInstanse.showMessage(message, 'success', duration)
      },
      // 失败
      error: (message, duration) => {
        MessageInstanse.showMessage(message, 'error', duration)
      },
      // 警告
      warning: (message, duration) => {
        MessageInstanse.showMessage(message, 'warning', duration)
      },
      // 关闭
      close: () => {
        MessageInstanse.closeMessage()
      },
    }
  },
}
export default MessagePlugin

<template>
  <div>
    <div class="mask" v-show="goout" @click="goout = false">
      <div style="position: relative; height: 100%">
        <div class="box" @click.stop="">
          <div class="confirm">Confirm to sign out？</div>
          <div class="btns">
            <div class="yes" @click="logout">Yes</div>
            <div class="Cancel" @click.stop="goout = false">Cancel</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixTop">
      <div class="nav">
        <img
          class="cursor"
          src="../assets/images/xuexi/logo.png"
          alt=""
          @click="$router.push('/')"
        />
        <div class="tabs">
          <div
            :class="{ hit: currentNav == 1 }"
            @click="
              $router.push({
                name: 'aboutdmz',
              })
            "
          >
            ABOUT
          </div>
          <div
            :class="{ hit: currentNav == 2 }"
            @click="
              $router.push({
                name: 'solution',
              })
            "
          >
            SOLUTION
          </div>
          <div
            :class="{ hit: currentNav == 3 }"
            @click="
              $router.push({
                name: 'stra',
              })
            "
          >
            PARTNERS
          </div>
          <div
            :class="{ hit: currentNav == 4 }"
            @click="
              $router.push({
                name: 'knowledge',
              })
            "
          >
            FAQ
          </div>
          <div
            :class="{ hit: currentNav == 5 }"
            @click="
              $router.push({
                name: 'news',
              })
            "
          >
            NEWS
          </div>
        </div>
        <div v-if="!!token" class="loginIcon">
          <connectWallet style="margin-right: 30px"></connectWallet>
          <div style="margin-right: 27px">
            <el-dropdown placement="bottom">
              <div
                @click="
                  $router.push({
                    name: 'messageList',
                  })
                "
              >
                <el-badge
                  :value="total_count ? total_count : ''"
                  class="badge"
                  :max="99"
                >
                  <img
                    src="../assets/images/mycenter/msg11.png"
                    alt=""
                    class="img50"
                  />
                </el-badge>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="messageBox">
                  <div class="flexBox" style="justify-content: space-between">
                    <div>
                      <img
                        src="@/assets/images/mycenter/msg.png"
                        alt=""
                        width="20px"
                      />
                      <span
                        style="
                          font-weight: bold;
                          font-size: 18px;
                          margin-left: 7px;
                        "
                        >Notifications</span
                      >
                    </div>
                  </div>
                  <div class="fiveMsgList">
                    <div
                      class="item"
                      v-for="(item, index) in unReadMessage"
                      :key="index"
                    >
                      <div
                        class="flexBox"
                        @click="
                          $router.push({
                            name: 'messageDetail',
                            params: {
                              id: item.message_guid,
                            },
                          })
                        "
                      >
                        <div style="margin-right: 15px">
                          <div class="logoBox">
                            <img :src="item.logo_url" alt="" />
                          </div>
                        </div>
                        <div style="flex: 1">
                          <div
                            class="flexBox"
                            style="justify-content: space-between"
                          >
                            <div>{{ item.publisher }}</div>
                            <div style="color: #bebebe">
                              {{ item.ctime }}
                            </div>
                          </div>
                          <div
                            style="
                              font-size: 14px;
                              font-weight: bold;
                              line-height: 30px;
                            "
                          >
                            Title:{{ item.title }}
                          </div>

                          <div class="content">
                            {{ item.abstract }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="!unReadMessage.length"
                      style="text-align: center"
                    >
                      No More
                    </div>
                  </div>
                  <div
                    v-if="!!unReadMessage.length"
                    class="readMore"
                    @click="
                      $router.push({
                        name: 'messageList',
                      })
                    "
                  >
                    VIEW MORE
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-dropdown placement="bottom-end">
            <span class="el-dropdown-link">
              <div
                v-if="cateG == 1"
                class="m"
                @click="
                  $router.push({
                    name: 'myindex',
                  })
                "
              >
                <img
                  class="img50"
                  src="../assets/images/mycenter/vippeople.png"
                  alt=""
                />
              </div>
              <div
                v-if="cateG == 5"
                class="m"
                @click="
                  $router.push({
                    name: 'myindex',
                  })
                "
              >
                <img
                  class="img50"
                  src="../assets/images/mycenter/people.png"
                  alt=""
                />
              </div>
              <div
                v-if="cateG == 3"
                class="m"
                @click="
                  $router.push({
                    path: '/exchange/burn',
                  })
                "
              >
                <img
                  class="img50"
                  src="../assets/images/mycenter/people.png"
                  alt=""
                />
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="flexBox" style="align-items: center">
                  <div style="margin-right: 21px">
                    <img
                      class="img50"
                      style="margin-top: 15px"
                      src="../assets/images/mycenter/people.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <div class="info">
                      <div>{{ emailx }}</div>
                    </div>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  v-if="cateG == 1 || cateG == 5"
                  class="droItem"
                  @click="
                    $router.push({
                      name: 'asset',
                    })
                  "
                >
                  <img
                    class="setimg"
                    src="../assets/images/mycenter/dashboard.png"
                    alt=""
                  />
                  <span>Dashboard</span>
                </div>
                <div
                  v-else-if="cateG == 3"
                  class="droItem"
                  @click="
                    $router.push({
                      path: '/exchange/burn',
                    })
                  "
                >
                  <img
                    class="setimg"
                    src="../assets/images/mycenter/dashboard.png"
                    alt=""
                  />
                  <span>Dashboard</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="cateG == 1 || cateG == 5">
                <div
                  class="droItem"
                  @click="
                    $router.push({
                      name: 'setting',
                    })
                  "
                >
                  <img
                    class="setimg"
                    src="../assets/images/mycenter/set.png"
                    alt=""
                  />
                  <span>Settings</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="cateG == 1 || cateG == 5">
                <div
                  class="droItem"
                  @click="
                    $router.push({
                      name: 'fileList',
                    })
                  "
                >
                  <img
                    class="setimg"
                    src="../assets/images/mycenter/docu.png"
                    alt=""
                  />
                  <span>Documents</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="droItem"
                  @click="
                    $router.push({
                      name: 'knowledge',
                    })
                  "
                >
                  <img
                    class="setimg"
                    src="../assets/images/mycenter/help.png"
                    alt=""
                  />
                  <span>Help</span>
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div class="droItem" @click="goout = true">
                  <img
                    class="outimg"
                    style="margin-right: 13x"
                    src="../assets/images/mycenter/sg.png"
                    alt=""
                  />
                  <span>Sign out</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="login" v-else>
          <div class="cursor" @click="$router.push('/login')">LOG IN</div>
          <img
            class="cursor"
            src="../assets/images/xuexi/reg.png"
            alt=""
            @click="
              $router.push({
                name: 'contact',
              })
            "
          />
        </div>
      </div>
    </div>

    <div
      class="bread"
      v-show="about2show"
      @mouseenter="about2show = true"
      @mouseleave="about2show = fasle"
    >
      <div class="w1200">
        <div class="aboutMenu">
          <div @click="$router.push('/aboutdmz')">About DMZ</div>
          <div @click="$router.push('/industry')">Industry Pain Point</div>
          <div @click="$router.push('/team')">Management Team</div>
        </div>
      </div>
    </div>

    <div
      class="bread"
      v-show="part2show"
      @mouseenter="part2show = true"
      @mouseleave="part2show = fasle"
    >
      <div class="w1200">
        <div class="aboutMenu">
          <div @click="$router.push('/stra')">Strategic Partners</div>
          <div @click="$router.push('/aprtp')">APRTP Members</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import connectWallet from '../components/connectWallet.vue'
export default {
  components: {
    connectWallet,
  },
  data () {
    return {
      isLogin: false,
      email: sessionStorage.getItem('email'),
      goout: false,
      about2show: false,
      part2show: false,
      categ: sessionStorage.getItem('categ'),
      currentNav: 0,
    }
  },
  // props: ['currentNav'],
  mounted () {
    this.init()

    // console.log('this',this);
    // var rname = this.$route.name
    // if(rname == 'aboutdmz' || rname == 'industry' || rname == 'team'){
    //   this.currentNav = 1;
    // }
  },
  methods: {
    ...mapActions(['asyncSetMessage']),
    init () {
      if (!!this.token) {
        this.asyncSetMessage()
      }
    },

    logout () {
      this.goout = false
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('setEmail', '')
      this.$router.push({
        path: '/login',
      })
    },

    gologin () {
      this.$router.push({
        path: '/login',
      })
    },

    goknowledge () {
      this.$router.push({
        path: '/knowledge',
      })
    },
    goindex () {
      this.$router.push({
        path: '/index',
      })
    },
    goinvest () {
      this.$router.push({
        path: '/investList',
      })
    },
    goreg () {
      this.$router.push({
        path: '/reg',
      })
    },
    goTransaction () {
      this.$router.push({
        path: '/transaction',
      })
    },
  },
  computed: {
    ...mapState(['total_count', 'unReadMessage', 'token', 'cateG', 'emailx']),
  },
  watch: {
    $route (to, from) {
      console.log('to', to.name)
      if (to.name == 'aboutdmz' || to.name == 'industry' || to.name == 'team') {
        this.currentNav = 1
      } else if (to.name == 'solution' || to.name == 'solution2') {
        this.currentNav = 2
      } else if (to.name == 'stra' || to.name == 'aprtp') {
        this.currentNav = 3
      } else if (to.name == 'knowledge') {
        this.currentNav = 4
      } else if (to.name == 'news' || to.name == 'media' || to.name == 'blogs') {
        this.currentNav = 5
      } else {
        this.currentNav = 0
      }
    },
  },
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .box {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 610px;
    height: 368px;
    background: #222222;
    border-radius: 24px;
    border: 1px solid #707070;
    .confirm {
      margin: 124px 0 85px;
      text-align: center;
      font-size: 30px;
      color: #fff;
    }
    .btns {
      margin: 0 auto;
      width: 70%;
      display: flex;
      justify-content: space-between;
      font-size: 22px;
    }
    .yes {
      cursor: pointer;
      width: 200px;
      height: 62px;
      background: linear-gradient(27deg, #19dd19 0%, #b4ffb4 100%);
      border-radius: 32px;
      text-align: center;
      line-height: 62px;
      color: #000;
    }
    .Cancel {
      cursor: pointer;
      width: 200px;
      height: 62px;
      border: 1px solid #707070;
      border-radius: 32px;
      text-align: center;
      line-height: 62px;
      color: #707070;
    }
  }
}
.fixTop {
  background: #000;
  font-size: 22px;
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 1000;
}

.nav {
  min-width: 1340px;
  box-sizing: border-box;
  padding: 0 85px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  img {
    width: 100px;
  }
  .tabs {
    cursor: pointer;
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      height: 80px;
      line-height: 80px;
      &.hit {
        color: #19dd19;
      }
    }
  }
  .login {
    display: flex;
    align-items: center;
    img {
      width: 160px;
      margin-left: 30px;
    }
  }
}
.loginIcon {
  display: flex;
  .img50 {
    width: 50px;
  }
}
::v-deep.badge .el-badge__content.is-fixed {
  top: 12px;
  right: 22px;
}
.messageBox {
  width: 450px;
  padding: 14px;
  padding-bottom: 5px;
}
.el-icon-setting {
  cursor: pointer;
  font-size: 17px;
}
.setimg {
  width: 23px;
}

.fileimg {
  width: 19px;
}

.shareimg {
  width: 25px;
}

.usdimg {
  width: 119px;
}

.helpimg {
  width: 24px;
}

.outimg {
  width: 22px;
}

.emailcss {
  font-size: 20px;
  padding-right: 30px;
}

.ft {
  position: absolute;
  right: 350px;
  top: 28px;
  width: 100px;
  font-size: 20px;
  cursor: pointer;
}

.ft2 {
  margin-right: 30px;
}

.regtxt {
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  text-align: center;
  color: #fff;
  background: #ce0d1b;
  border-radius: 6px;
  font-size: 24px;
}

.jianti {
  width: 80px;
  vertical-align: middle;
}

.flexl2 {
  width: 120px;
}
.img30 {
  width: 52px;
}
.langu {
  vertical-align: middle;
  font-size: 22px;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  //鼠标悬停样式
  background: #222;
  color: #fff;
}

———————————————— .el-icon-arrow-down {
  position: relative;
  top: -3px;
}
.img100 {
  width: 100px;
}
.el-dropdown-menu {
  background: #000;
  border: 1px solid #707070;
  color: #fff;
}
.droItem {
  height: 50px;
  line-height: 50px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
  }
  span {
    font-size: 20px;
    vertical-align: middle;
    font-weight: 400;
    color: #fff;
  }
}
.fiveMsgList {
  margin-top: 26px;
  .item {
    cursor: pointer;
    background: #000;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    .content {
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 14px;
      color: #bebebe;
    }
  }
}
.logoBox {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.readMore {
  text-align: end;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
::v-deep .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background: #19dd19;
}

.bread {
  height: 60px;
  background: #555;
  line-height: 60px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: 999;
}

.w1200 {
  width: 1200px;
  margin: 0 auto;
}

.aboutMenu {
  display: flex;
  > div {
    flex: 1;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    &.hit {
      color: #19dd19;
    }
  }
}
</style>

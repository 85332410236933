import Web3 from 'web3'
import { Message } from 'element-ui'
// import { ConfigContract } from '../../Web3/Currency/CurrencyList'
export class Coinbase {
  constructor() {}
  /**
   *  * 打开钱包
   *  支持的钱包 Metamask、coinbase
   */
  openWallet = (resolve, reject) => {
    // window.ethereum.close()
    // 调用 浏览器中的metamask 插件进行授权
    // 老版本的启动方式
    // window.ethereum.enable().then(async res => {
    // 新版本启动方式
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(async (res) => {
        // 用户授权成功
        // 1. web3 初始化
        window.web3 = new Web3(window.ethereum)
        console.log('givenProvider', window.web3.currentProvider)
        // window.web3.setProvider('ws://8ljem3xhc7lk.ngrok2.xiaomiqiu.cn')
        // 2. 创建所有合约
        // ConfigContract.forEach(item => {
        //     this.createContract(item.key, item.CONTRACTADDRESS, item.ABI)
        // })
        resolve(res)
        // 返回 所有账号的token   是一个数组
      })
      .catch((error) => {
        // 用户不授权
        Message({
          message: error.message,
          type: 'error',
        })
        reject(error)
      })
  }
  // 登出
  duankai() {
    // window.ethereum.close();
    window.ethereum.disconnect()
    console.log(window)
  }
  /**
   *  * 连接Coinbase钱包，并将web3 实例挂载在window 上
   * @returns  账号地址
   */
  connectWallet = () => {
    return new Promise((resolve, reject) => {
      // 浏览器装了 metamask
      if (window.ethereum) {
        console.log(window.ethereum.isCoinbaseWallet)
        console.log(window.ethereum.providers)
        // 如果用户同时安装了 Metamask 和 Coinbase 钱包扩展，
        // 连接到注入的提供程序可能会导致两个扩展都会弹出一个弹出窗口来连接钱包。
        // 如果用户安装了多个注入以太坊提供者的钱包浏览器扩展（例如，MetaMask 和 Coinbase 钱包），
        // Coinbase 钱包的注入提供者将在包含来自每个钱包的注入提供者处构造一个“多提供者”数组。
        // Coinbase Wallet 可以在这个数组中通过属性来识别。window.ethereum.providers    isCoinbaseWallet
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.find(async (p) => {
            console.log(p)
            if (p.isCoinbaseWallet) {
              window.ethereum = p
              console.log('唤醒coinbase第一次', window)
              this.openWallet(resolve, reject)
            }
          })
        } else if (window.ethereum.isCoinbaseWallet) {
          console.log('唤醒coinbase第二次', window)
          this.openWallet(resolve, reject)
        } else {
          location.reload()
          console.log('安装coinbase 第一次', window)
          Message({
            message: 'Please install Coinbase plug-in first',
            type: 'warning',
          })
          reject('Please install the Coinbase')
        }
        // try{
        // const accounts = await window.ethereum.request({method:"eth-requestAccounts"})
        // }
      } else {
        console.log('安装coinbase 第二次', window)
        Message({
          message: 'Please install Coinbase plug-in first',
          type: 'warning',
        })
        reject('Please install the Coinbase')
      }
    })
  }
  getBalance = () => {
    return new Promise((resolve, reject) => {
      // 浏览器装了 metamask
      let web3 = new Web3(window.web3.currentProvider)
      web3.eth.getAccounts((e, r) => {
        if (e) {
          Message({
            message: error.message,
            type: 'error',
          })
          reject(e)
        } else {
          console.log(r[0])
          web3.eth.getBalance(r[0]).then((res) => {
            resolve(web3.utils.fromWei(res, 'ether'))
          })
        }
      })
    })
  }

  /**
   *  * 生成web3合约，并将挂载在window上
   * @param {* ABI 智能合约 的一个JSON} ABI
   * @param {* 智能合约的地址} CONTRACTADDRESS
   * @param {* 生成的智能合约对象  将挂载在window上  key为其健名} key
   * @returns 智能合约对象
   */
  createContract = (key, CONTRACTADDRESS, ABI) => {
    try {
      const Contract = new window.web3.eth.Contract(ABI, CONTRACTADDRESS)
      console.log(`window.${key} 合约对象 :`, Contract)
      window[key] = Contract
      this.getPastEvents(Contract, key)
      return Contract
    } catch {
      console.error(`创建 window.${key} 合约对象 失败`)
    }
  }
  // 监听
  getPastEvents = (MyContract, key) => {
    // MyContract.getPastEvents('allEvents', {
    //     // filter: {},
    //     // fromBlock: 0,
    //     // toBlock: 'latest'
    // }, function(error, events){
    //     console.log(`${key}`,events);
    //  })
    // .then(function(events){
    //     console.log(`${key}`,events);

    //     // console.log(events) // same results as the optional callback above
    // });

    // const a =  MyContract.events.Transfer()

    MyContract.events
      .Transfer(
        {
          filter: {},
          fromBlock: 'latest',
        },
        function (error, event) {
          console.log(key, '1', error, event)
        }
      )
      .on('data', function (event) {
        console.log('2', event) // same results as the optional callback above  1
      })
      .on('changed', function (event) {
        console.log('emove event from local database', event)
      })
      .on('error', console.error)
  }
}

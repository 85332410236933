import Web3 from 'web3'
import { Message } from 'element-ui'

// import abi from '../contracts/MyContract.json'
// import { ConfigContract } from "../../Web3/Currency/CurrencyList";

export class Metamask {
  //   constructor() {}
  /**
   *  * 打开钱包
   *  支持的钱包 Metamask、coinbase
   */
  openWallet = (resolve, reject) => {
    // window.ethereum.close()
    // 调用 浏览器中的metamask 插件进行授权
    // 老版本的启动方式
    // window.ethereum.enable().then(async res => {
    // 新版本启动方式
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(async (res) => {
        // 用户授权成功
        // 1. web3 初始化
        window.web3 = new Web3(window.ethereum)
        window.ethereum.on('accountsChanged', () => {
          location.reload()
        })
        window.ethereum.on('networkChanged', () => {
          location.reload()
        })
        // window.web3.setProvider('ws://8ljem3xhc7lk.ngrok2.xiaomiqiu.cn')
        // 2. 创建所有合约
        // ConfigContract.forEach((item) => {
        //   this.createContract(item.key, item.CONTRACTADDRESS, item.ABI);
        // });
        resolve(Web3.utils.toChecksumAddress(res[0]))

        // 返回 所有账号的token   是一个数组
      })
      .catch((error) => {
        // 用户不授权
        reject(error)
      })
  }

  /**
   *  * 连接MetaMask钱包，并将web3 实例挂载在window 上
   * @returns  账号地址
   */
  connectWallet = () => {
    return new Promise((resolve, reject) => {
      // 浏览器装了 metamask
      if (window.ethereum) {
        // 如果用户同时安装了 Metamask 和 Coinbase 钱包扩展，
        // 连接到注入的提供程序可能会导致两个扩展都会弹出一个弹出窗口来连接钱包。
        // 如果用户安装了多个注入以太坊提供者的钱包浏览器扩展（例如，MetaMask 和 Coinbase 钱包），
        // Coinbase 钱包的注入提供者将在包含来自每个钱包的注入提供者处构造一个“多提供者”数组。
        // Coinbase Wallet 可以在这个数组中通过属性来识别。window.ethereum.providers    isCoinbaseWallet
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            if (p.isMetaMask) {
              window.ethereum = p
              this.openWallet(resolve, reject)
            }
          })
        } else if (window.ethereum.isMetaMask) {
          this.openWallet(resolve, reject)
        } else {
          location.reload()
          console.log('安装metamask第一次', window)
          Message({
            message: '请先安装Metamask插件或重试',
            type: 'warning',
          })
          reject(new Error('请先安装 metamask 插件'))
        }
        // try{
        // const accounts = await window.ethereum.request({method:"eth-requestAccounts"})
        // }
      } else {
        console.log('安装metamask第二次')
        Message({
          message: '请先安装Metamask插件',
          type: 'warning',
        })
        reject(new Error('请先安装 metamask 插件'))
      }
    })
  }
  // 查询余额
  getBalance = () => {
    return new Promise((resolve, reject) => {
      // 浏览器装了 metamask
      let web3 = new Web3(window.ethereum)
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((res) => {
          web3.eth
            .getBalance(res[0])
            .then((res) => {
              resolve(web3.utils.fromWei(res, 'ether'))
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  //ETH转账
  getTransfer(to, amount) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum
        .request({
          method: 'eth_requestAccounts',
        })
        .then((res) => {
          let fromAddress = res[0]
          // let amount = 1
          // let toAddress = '0xf0619a5f302fa0a0F9CFa7E98f5Da4bB4BA601d0'
          try {
            web3.eth.sendTransaction(
              {
                gas: 21000,
                gasPrice: 5000000000,
                from: fromAddress,
                to,
                value: amount * Math.pow(10, 18),
              },
              (e, contract) => {
                if (e) {
                  reject(e)
                } else {
                  resolve(contract)
                }
              }
            )
          } catch (error) {
            reject(error)
          }
        })
    })
  }
  // abi合约
  async abiget(abi, dress, setFn, arr) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      let abiContract = new web3.eth.Contract(abi, dress)

      // 0x077A4d3e5C9b3Cd15B9C97ba104b962de67416D6
      // 0xC494472EC559534Ec1570efA42FE3c3dB7Ee4023
      abiContract.methods[setFn](...arr).call((err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }
  async abiset(abi, dress, setFn, arr) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        try {
          let abiContract = new web3.eth.Contract(abi, dress)
          abiContract.methods[setFn](...arr)
            .send({
              from: res[0],
            })
            .on('receipt', (data) => {
              console.log(JSON.stringify(data), 'receipt')
              resolve(data)
            })
            .on('error', (err) => {
              console.log(JSON.stringify(err), 'error')
              reject(err)
            })
        } catch (error) {
          reject(error)
        }
      })
    })
  }
  // 查询合约小数
  async getDecimals(dress) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(async (res) => {
          try {
            let ethContract = new web3.eth.Contract(erc, dress)
            let decimals = await ethContract.methods.decimals().call()
          } catch (error) {
            reject(error)
          }
        })
    })
  }
  //授权
  async getApprove(tokenAbi, dress, toAddress, amount) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        try {
          let ethContract = new web3.eth.Contract(tokenAbi, dress)
          ethContract.methods
            .approve(toAddress, amount)
            .send({ from: res[0] })
            .on('receipt', (data) => {
              resolve(data)
            })
            .on('error', (err) => {
              reject(err)
            })
        } catch (error) {
          reject(error)
        }
      })
    })
  }
  /**
   *
   * @param {token地址 } dress
   * @returns
   */
  async getAllowance(tokenAbi, dress) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        try {
          let ethContract = new web3.eth.Contract(tokenAbi, dress)
          ethContract.methods
            .balanceOf(res[0])
            .call()
            .then((balance) => {
              console.log(`余额: ${balance / Math.pow(10, 8)}`)
              resolve(balance / Math.pow(10, 8))
            })
            .catch((error) => {
              reject(error)
              console.error('查询余额时发生错误:', error)
            })
        } catch (error) {
          reject(error)
        }
      })
    })
  }
  async abigetBalance(abi, dress, arr) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        try {
          let abiContract = new web3.eth.Contract(abi, dress)
          abiContract.methods
            .getBalance(...arr)
            .call()
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
              console.error('查询余额时发生错误:', error)
            })
          // .on('receipt', (data) => {
          //   console.log(JSON.stringify(data))
          //   console.log(11113123123213)
          //   resolve(data)
          // })
          // .on('error', (err) => {
          //   reject(err)
          // })
        } catch (error) {
          reject(error)
        }
      })
    })
  }

  async abigetUnstake(abi, dress, id) {
    return new Promise((resolve, reject) => {
      let web3 = new Web3(window.ethereum)
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
        try {
          let abiContract = new web3.eth.Contract(abi, dress)
          abiContract.methods
            .getUnstake(id)
            .call()
            .then((res) => {
              resolve(res)
            })
            .catch((error) => {
              reject(error)
              console.error('查询详情发生错误:', error)
            })
        } catch (error) {
          reject(error)
        }
      })
    })
  }
}

// 导入 类 钱包
import { Metamask } from '@/utils/wallet/api/metamask'
import { Coinbase } from '@/utils/wallet/api/coinbase'
// import { Walletconnect } from "@/utils/wallet/api/walletConnect";

export default {
  Metamask,
  Coinbase
//   Walletconnect,
}

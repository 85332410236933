import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/loginGuid',
    name: 'loginGuid',
    component: () => import('@/views/loginGuid.vue'),
  },
  {
    path: '/next',
    name: 'next',
    component: () => import('@/views/next.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue'),
  },
  {
    path: '/abidemo',
    name: 'abidemo',
    component: () => import('@/views/wallet/abidemo.vue'),
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/knowledge.vue'),
  },

  {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/views/logincode.vue'),
  },

  {
    path: '/forgetpwdemail',
    name: 'forgetpwdemail',
    component: () => import('@/views/forgetpwdemail.vue'),
  },
  {
    path: '/forgetpwdemailstatic',
    name: 'forgetpwdemailstatic',
    component: () => import('@/views/forgetpwdemailstatic.vue'),
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd.vue'),
  },

  {
    path: '/aboutdmz',
    name: 'aboutdmz',
    component: () => import('@/views/about/aboutdmz.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/about/team.vue'),
  },

  {
    path: '/aprtp',
    name: 'aprtp',
    component: () => import('@/views/about/aprtp.vue'),
  },

  {
    path: '/stra',
    name: 'stra',
    component: () => import('@/views/about/stra.vue'),
  },

  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/about/solution.vue'),
  },
  {
    path: '/solution2',
    name: 'solution2',
    component: () => import('@/views/about/solution2.vue'),
  },

  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/about/industry.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/about/news.vue'),
  },
  {
    path: '/media',
    name: 'media',
    component: () => import('@/views/about/media.vue'),
  },
  {
    path: '/eventDetail/:id',
    name: 'eventDetail',
    component: () => import('@/views/about/eventDetail.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('@/views/about/events.vue'),
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('@/views/about/newsDetail.vue'),
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('@/views/about/blogs.vue'),
  },
  {
    path: '/blogDetail/:id',
    name: 'blogDetail',
    component: () => import('@/views/about/blogDetail.vue'),
  },

  {
    path: '/my/home',
    name: 'my',
    component: () => import('@/views/My/index.vue'),
    redirect: '/my/index',
    children: [
      {
        path: '/my/index',
        name: 'myindex',
        component: () => import('@/views/My/home.vue'),
        redirect: '/my/asset',

        children: [
          {
            path: '/my/asset',
            name: 'asset',
            component: () => import('@/views/My/walletplay/asset.vue'),
          },
          {
            path: '/investDetail',
            name: 'investDetail',
            component: () => import('@/views/investDetail.vue'),
          },
          {
            path: '/my/mint',
            name: 'mint',
            component: () => import('@/views/My/walletplay/mint.vue'),
          },
          {
            path: '/my/stake',
            name: 'stake',
            component: () => import('@/views/My/walletplay/stake.vue'),
          },
          {
            path: '/my/unstake',
            name: 'unstake',
            component: () => import('@/views/My/walletplay/unstake.vue'),
          },
          {
            path: '/my/vaultunstake',
            name: 'vaultunstake',
            component: () => import('@/views/My/walletplay/vaultUnstake.vue'),
          },
          {
            path: '/my/burn',
            name: 'burn',
            component: () => import('@/views/My/walletplay/burn.vue'),
          },
          {
            path: '/my/unstakevote',
            name: 'unstakevote',
            component: () => import('@/views/My/walletplay/unstakereq.vue'),
          },
        ],
      },

      {
        path: '/exchange/index',
        name: 'exchangeindex',
        component: () => import('@/views/My/home_ex.vue'),
        redirect: '/exchange/asset',

        children: [
          {
            path: '/exchange/asset',
            name: 'ex_asset',
            component: () => import('@/views/My/exchangeplay/asset.vue'),
          },
          {
            path: '/investDetail',
            name: 'investDetail',
            component: () => import('@/views/investDetail.vue'),
          },
          {
            path: '/exchange/mint',
            name: 'ex_mint',
            component: () => import('@/views/My/exchangeplay/mint.vue'),
          },
          {
            path: '/exchange/stake',
            name: 'ex_stake',
            component: () => import('@/views/My/exchangeplay/stake.vue'),
          },
          {
            path: '/exchange/unstake',
            name: 'ex_unstake',
            component: () => import('@/views/My/exchangeplay/unstake.vue'),
          },
          {
            path: '/exchange/burn',
            name: 'ex_burn',
            component: () => import('@/views/My/exchangeplay/burn.vue'),
          },
          {
            path: '/exchange/unstakereq',
            name: 'ex_unstakereq',
            component: () => import('@/views/My/exchangeplay/unstakereq.vue'),
          },
          {
            path: '/exchange/unstakedst',
            name: 'ex_unstakedst',
            component: () => import('@/views/My/exchangeplay/unstake.vue'),
          },
        ],
      },

      {
        path: '/my/messageList',
        name: 'messageList',
        component: () => import('@/views/My/messageList.vue'),
      },
      {
        path: '/my/messageDetail/:id',
        name: 'messageDetail',
        component: () => import('@/views/My/messageDetail.vue'),
      },
      {
        path: '/my/fileList',
        name: 'fileList',
        component: () => import('@/views/My/fileList.vue'),
      },
      {
        path: '/my/setting',
        name: 'setting',
        component: () => import('@/views/My/setting.vue'),
      },
    ],
  },
  {
    // 重定向路由
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
